import React, { useState, useEffect } from 'react'
import { Table, Grid, Typography, TablePagination, MenuItem, createStyles, withStyles, Theme, Menu } from "@material-ui/core";
import { TableCell, TableSortLabel, TableRow, TableBody, TableContainer, TableHead, Paper } from "@material-ui/core";
import Pagination from "material-ui-flat-pagination";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import IconButton from "@material-ui/core/IconButton";

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        root: {
            borderBottom: "none",
        },
        head: {
            backgroundColor: "#464659",
            color: "#FFFFFF",
            borderBottom: "none",
            padding: "8px 16px",
            fontWeight: "bold",
        },
        body: {
            fontSize: 14,
            color: "#FFFFFF",
            backgroundColor: "none",
            padding: "12px",
        },
    })
)(TableCell);
const StyledTableSort = withStyles({
    root: {
        color: '#ffffff',
    },
    icon: {
        color: '#ffffff !important',
        fontSize: "26px"
    },
})(TableSortLabel)


const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: "#2B2B36",
            },
        },
    })
)(TableRow);


const getFilteredItem = (items: any, search: string) => {
    const _search = search.toLowerCase();
 
    return items.filter((item: any) => {
        if (item.role_name && item.role_name.toLowerCase().includes(_search)) {
            return true
        }
        if (item.no_of_users != null && (item.no_of_users + '').toLowerCase().includes(_search)) {
            return true
        }
        return false
    })
}

const getSortedItem = (sortBy: string, sortOrder: string, selectedApplication: any) => {
    const itemsToSort = JSON.parse(JSON.stringify(selectedApplication));
    const compareFn = (i: any, j: any) => {
        if (i[sortBy] < j[sortBy]) {
            return sortOrder === "asc" ? -1 : 1;
        } else {
            if (i[sortBy] > j[sortBy]) {
                return sortOrder === "asc" ? 1 : -1;
            } else {
                return 0;
            }
        }
    }
    return itemsToSort.sort(compareFn);
}
interface RoleTableViewProps {
    isForm: boolean;
    isFormEdit: boolean;
    classes: { [key: string]: string };
    selectedApplication: any;
    search: string;
    handleClick: (e: any) => (e: any) => void;
    anchorEl: any;
    menu: any;
    handleClose: (e: any) => () => void;
    handleEdit: (e: any) => void;
    onView: (e: any) => void;
    dialogHandleDelete: (e: any, a: any) => void;


}

export default function RoleTableView({
    isForm,
    classes,
    isFormEdit,
    selectedApplication,
    search,
    handleClick,
    anchorEl,
    menu,
    handleClose,
    handleEdit,
    dialogHandleDelete,
    onView,



}: RoleTableViewProps) {
    const [defaultSortBy, setDefaultSortBy] = useState("role_name");
    const [defaultSortOrder, setDefaultSortOrder] = useState("asc");
    const [sortedItemRows, setSortItemRows] = useState(getSortedItem(defaultSortBy, defaultSortOrder, selectedApplication));
    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleClickPaginate = (offset: number) => {
        setOffset(offset);
        setPage(offset / rowsPerPage);
    };
    useEffect(() => {
        let rows = selectedApplication;
        if (search.length) {
            setOffset(0);
            setPage(0);
            rows = getFilteredItem(selectedApplication, search)
        }

        const sortedItems = getSortedItem(defaultSortBy, defaultSortOrder, rows);
        setSortItemRows(sortedItems);


    }, [defaultSortBy, defaultSortOrder, selectedApplication, search])

    const sortByColumn = (columnName: any) => {
        if (columnName === defaultSortBy) {
            setDefaultSortOrder(defaultSortOrder === "asc" ? "desc" : "asc");
        } else {
            setDefaultSortOrder("asc");
            setDefaultSortBy(columnName)
        }
    }
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [offset, setOffset] = useState(0);

    return (
        <>
            <TableContainer component={Paper} className={classes.tableWrapper}>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>
                                <StyledTableSort
                                    active={defaultSortBy === "role_name"}
                                    direction={defaultSortOrder === 'asc' ? 'asc' : 'desc'}
                                    onClick={() => sortByColumn("role_name")}
                                    IconComponent={ArrowDropDown}
                                    style={{ color: 'white' }}
                                >
                                    Role Name
                                </StyledTableSort>
                            </StyledTableCell>
                            <StyledTableCell><StyledTableSort
                                active={defaultSortBy === "no_of_users"}
                                direction={defaultSortOrder === 'asc' ? 'asc' : 'desc'}
                                onClick={() => sortByColumn("no_of_users")}
                                IconComponent={ArrowDropDown}
                                style={{ color: 'white' }}
                            >
                                Number of Users
                            </StyledTableSort></StyledTableCell>
                            <StyledTableCell>&nbsp;</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    {selectedApplication.length > 0 ? (
                        <TableBody>
                            {(sortedItemRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            ).map((row: any, index: number) => (
                                <StyledTableRow key={row.role_id}>
                                    <StyledTableCell>{row.role_name}</StyledTableCell>
                                    <StyledTableCell>{row.no_of_users}</StyledTableCell>
                                    <StyledTableCell>
                                        <IconButton
                                            onClick={handleClick(index)}
                                            classes={{ root: classes.menuButtonRoot }}
                                        >
                                            <MoreVertIcon color="secondary" />
                                        </IconButton>
                                        <Menu
                                            id="user-menu"
                                            anchorEl={anchorEl}
                                            open={menu[index]}
                                            onClose={handleClose(index)}
                                            classes={{ paper: classes.menuRoot }}
                                            transformOrigin={{
                                                vertical: "top",
                                                horizontal: "right",
                                            }}
                                        >
                                            <MenuItem
                                                className={`${classes.menuitem} ${classes.viewDetails}`}
                                                onClick={() => onView(row)}
                                            >
                                                <VisibilityIcon></VisibilityIcon>
                                                View Details
                                            </MenuItem>
                                            <MenuItem
                                                className={`${classes.menuitem} ${classes.viewDetails}`}
                                                onClick={() => handleEdit(row)}
                                            >
                                                <EditIcon></EditIcon>
                                                Edit
                                            </MenuItem>
                                            <MenuItem
                                                className={`${classes.menuitem} ${classes.viewDetails}`}
                                                onClick={() =>
                                                    dialogHandleDelete(row.role_id, row.application_id)
                                                }
                                            >
                                                <DeleteOutlinedIcon></DeleteOutlinedIcon>
                                                Delete
                                            </MenuItem>
                                        </Menu>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    ) : (
                        <div style={{ color: "white", paddingTop: "1.2rem" }}>
                            No Records Found.
                        </div>
                    )}
                </Table>
            </TableContainer>

            <Grid container xs={12} direction='row' alignItems='center' justifyContent='space-between' style={{ paddingLeft: '1.6em', marginRight: "13px" }}>
                <Grid xs={4} item container direction='row' alignItems='center'>
                    <Typography className={classes.paginateView}>View: </Typography>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={sortedItemRows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        labelRowsPerPage={"Role per page :"}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        className={classes.tblpaginate}
                    />
                    <Typography className={classes.paginateUser}>
                        Role per page
                    </Typography>
                </Grid>
                <Pagination
                    limit={rowsPerPage}
                    offset={offset}
                    onClick={(e, offset) => handleClickPaginate(offset)}
                    total={sortedItemRows.length}
                    className={classes.tblpaginatenumber}
                    currentPageColor="secondary"
                    otherPageColor="primary"
                />
            </Grid>
        </>
    )
}
