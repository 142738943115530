import React, { useEffect, useState } from "react";
import {
  createStyles,
  Theme,
  makeStyles,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import { exportToExcel } from "../../utils/helper";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import ColumnIcon from "@material-ui/icons/ViewColumnOutlined";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Backdrop,
  CircularProgress,
  ClickAwayListener,
  FormControl,
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import AddConnConfig from "./AddConnectionConfiguration";
import deleteLogo from "../../assets/Delete.png";
import exportLogo from "../../assets/Export.png";
import EditConnConfig from "./EditConnectionConfiguration";
import { SnackbarProvider, useSnackbar } from "notistack";
import TablePagination from "@material-ui/core/TablePagination";
import Pagination from "material-ui-flat-pagination";
import "../Users/Pagination.css";
import TableFooter from "@material-ui/core/TableFooter";
import { API } from "../../api/property";
import callXhrRequest from "../../utils/xhrRequestHandler";
import { arrayOfErrorsRefresh, arrayOfErrorsLogout } from '../../utils/helper';
import { useStore } from "mobx-store-provider";
import BreadcumbComponent from '../../utils/breadcurmb';
import ApplicationSelector from "../Devices/DeviceInstallation/ApplicationSelector";
import { useApplicationContext } from "../../contexts/ApplicationContext";
const font_family = "Avenir Next";

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: "none",
    },
    head: {
      backgroundColor: "#464659",
      color: "#FFFFFF",
      borderBottom: "none",
      padding: "8px 16px",
      fontWeight: "bold",
    },
    body: {
      fontSize: 14,
      color: "#FFFFFF",
      backgroundColor: "none",
      padding: "12px",
    },
  })
)(TableCell);

const CssTextField = withStyles({
  root: {
    "& input": {
      padding: "10px",
      color: "#ffffff",
      backgroundColor: "#1D1D25 ",
      border: "1px solid #464659",
      borderRadius: "2px",
    },
    "& .MuiInput-multiline": {
      padding: "10px",
      color: "#ffffff",
      backgroundColor: "#1D1D25 ",
      border: "1px solid #464659",
      borderRadius: "2px",
      marginTop: "32px",
    },
    "& .MuiInput-underline": {
      "&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
    "& label.Mui-focused": {
      color: "#ffffff",
    },
  },
})(TextField);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: "#2B2B36",
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hidden: {
      display: "none",
    },
    gridRoot: {
      flexGrow: 1,
      padding: "0px 24px",
      backgroundColor: "#2B2B36",
    },
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    flexContainer: {
      display: "flex",
      flexDirection: "row",
    },
    listButtonWrap: {
      marginLeft: "20px",
    },
    listItem: {
      width: "auto",
      padding: "0px",
      marginRight: "20px",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#FFFFFF",
    },
    listItemText: {
      fontSize: "16px",
      lineHeight: "19px",
    },
    tableWrapper: {
      padding: "0 20px",
      marginTop: "15px",
      boxShadow: "none",
    },
    dividerColor: {
      background: "#464659",
      margin: "1em",
    },
    table: {
      minWidth: "100%",
      maxWidth: "100%",
    },
    formControl: {
      margin: "0 25px",
    },
    listButton: {
      padding: "0px",
      textTransform: "none",
      fontSize: "14px",
      whiteSpace: "nowrap",
    },
    rightSec: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      whiteSpace: "nowrap",
      padding: "5px 0px",
      fontSize: "12px",
      lineHeight: "14px",
      "& p": {
        fontSize: "12px",
        padding: "11px 0px",
        marginRight: "30px",
      },
      "& button": {
        background: "#0089FF",
        borderRadius: "2px",
        fontSize: "16px",
        color: "#FCFCFC",
        padding: "6px 20px",
        marginRight: "10px",
        "&:hover": {
          background: "#0089FF",
        },
      },
    },
    menuButtonRoot: {
      padding: "0px",
    },
    viewDetails: {
      color: "#0089FF !important",
    },
    menuRoot: {
      backgroundColor: "#1D1D25",
      border: "1px solid #33333F",
      boxShadow: "0px 4px 4px rgba(0,0,0,0.25)",
      borderRadius: "2px",
    },
    cardTitle: {
      fontSize: "18px",
      fontWeight: "bold",
      lineHeight: "21px",
      color: "#FFFFFF",
      marginBottom: "16px",
    },
    cardText: {
      fontSize: "16px",
      lineHeight: "19px",
      color: "#FFFFFF",
      marginBottom: "10px",
    },
    CardStyle: {
      background: "#2B2B36",
      borderRadius: "3px",
    },
    cardGrid: {
      marginBottom: "20px",
    },
    labels: {
      color: "#fcfcfc",
      lineHeight: "16px",
      fontSize: "14px",
      transform: "scale(1)",
    },
    menuitem: {
      color: "white",
      fontSize: "14px",
      paddingRight: "40px",
      "&:hover": {
        color: "#0089FF !important",
      },
      "& svg": {
        marginRight: "8px",
      },
    },
    dialogRoot: {
      boxShadow: "0px 30px 100px #000000",
      borderRadius: "3px",
      maxWidth: "850px",
      minWidth: "850px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
        marginBottom: "25px",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: "1px solid rgba(235,235,245,0.35)",
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    dialogRootSmall: {
      boxShadow: "0px 30px 100px #000000",
      borderRadius: "3px",
      maxWidth: "800px",
      position: "absolute",
      top: "10%",
      minWidth: "416px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "15px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: "1px solid rgba(235,235,245,0.35)",
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },

    tblpaginate: {
      color: "#fff",
    },
    tblfooter: {
      position: "absolute",
    },
    tblfooter1: {
      position: "relative",
      padding: "10px 0",
      display: "flex",
      alignItems: "center",
      justify: 'space-between'
    },
    tblpaginatenumber: {
      color: "#fff",
    },
    paginateUser: {
      color: "#fff",
    },
    paginateView: {
      color: "#fff",
    },
    label: {
      fontSize: "18px",
      lineHeight: "21px",
      marginRight: "18px",
      color: "#FFF",
    },
    dropdown: {
      width: "207px",
      height: "40px",
      background: "transparent",
      padding: "10px",
      color: "#FFFFFF",
      border: "1px solid #4CA5FF",
      font: "400 13.3333px 'Avenir Next'",
      borderRadius: "2px",
      marginRight: "30px",
      "& option": {
        background: "#1D1D25",
      },
    },
    alignCentre: {
      alignItems: "center",
      marginTop: "0.5rem",
    },
  })
);

const DialogTitleStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: "20px",
      padding: 0,
      color: "#FFFFFF",
    },
    title: {
      fontSize: "21px",
      lineHeight: "25px",
      fontWeight: "bold",
    },
    closeButton: {
      position: "absolute",
      right: "2px",
      top: "15px",
      color: "#FFFFFF",
      fontSize: "14px",
    },
  });
const DialogContent = withStyles((theme: Theme) => ({
  root: {
    margin: "0px 15px",
    padding: 0,
    color: "#FFFFFF",
  },
}))(MuiDialogContent);
const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: "20px 15px",
    justifyContent: "space-between",
    padding: 0,
    "& button": {
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
  },
}))(MuiDialogActions);

export interface DialogTitleProps extends WithStyles<typeof DialogTitleStyles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}
const DialogTitle = withStyles(DialogTitleStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


function ConfigsWrapper() {
  const API_KEY = window.SERVER_DATA.REACT_APP_PM_API_KEY;
  const { user } = useStore();

  const classes = useStyles();
  const [isForm, setIsForm] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    config: any
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedConfig(config);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openDialogCc, setDialogOpenCc] = React.useState(false);
  const dialogHandleClickOpenCc = () => {
    setDialogOpenCc(true);
  };
  const dialogHandleCloseCc = () => {
    setDialogOpenCc(false);
  };

  const [openDialogView, setOpenDialogView] = React.useState(false);
  const dialogHandleClickOpenView = () => {
    setOpenDialogView(true);
  };
  const dialogHandleCloseView = () => {
    setOpenDialogView(false);
  };

  const { enqueueSnackbar } = useSnackbar();
  const [loadConfig, setLoadConfig] = React.useState(false);
  const [sortAsc, setSortAsc] = React.useState(true);
  const [sortDateAsc, setDateSortAsc] = React.useState(true);
  const [selectedConfig, setSelectedConfig] = React.useState({} as any);
  const [openDialogDelete, setOpenDialogDelete] = React.useState(false);
  const dialogHandleClickOpenDelete = () => {
    setOpenDialogDelete(true);
  };

  const dialogHandleCloseDelete = () => {
    setOpenDialogDelete(false);
  };

  const saveDelete = () => {
    const tokenKey = "sso_token";
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    interface sessionObj {
      pm_s_id: string;
    }
    interface tokenObj {
      pm_s_token: string;
    }
    let pmSessionId: sessionObj = {
      pm_s_id: "",
    };
    let pmSessionToken: tokenObj = {
      pm_s_token: "",
    };
    pmSessionId =
      sessionStorage.getItem("pm_s_id") ||
      JSON.parse(JSON.stringify(pmSessionId));
    pmSessionToken =
      sessionStorage.getItem("pm_s_token") ||
      JSON.parse(JSON.stringify(pmSessionToken));
    const DELETE_API = `${API["GETALLAPPLS"]}/${selectedApplication.application_id}/conn-configs/${selectedConfig.conn_config_id}?pm_s_id=${pmSessionId}&pm_s_token=${pmSessionToken}`;
    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken.access_token}`,
        'Ocp-Apim-Subscription-Key': `${API_KEY}`,
        'Ocp-Apim-Trace': `true`
      },
    };
    fetch(DELETE_API, requestOptions)
      .then(function (response: any) {
        response.json().then((response: any) => {
        if ((response && response.status === 401) && (response && arrayOfErrorsRefresh.includes(response.message.toLowerCase()))) {
          try {
            const tokenKey = "sso_token";
            setbackDrop(true);
            callXhrRequest().then(function (data) {
              sessionStorage.setItem(tokenKey, data);
              setbackDrop(false);
              saveDelete();
            }).catch(function (error) {
              user.triggerLogout();
              console.log('error', error)
            })
          } catch (error) {
            console.log(error);
          }
          return;
        }
        else if ((response && response.status === 401) && (response && arrayOfErrorsLogout.includes(response.message.toLowerCase()))) {
          try {
            user.triggerLogout();
          } catch (error) {
            console.log(error);
          }
          return;
        }
        else if (response && response.status === 200) {
          setOpenDialogDelete(false);
          enqueueSnackbar("Configuration Deleted", { variant: "success" });
          handleClose();
          setLoadConfig(!loadConfig);
        } else {
          setOpenDialogDelete(false);
          enqueueSnackbar("Configuration Deletion Failed", {
            variant: "error",
          });
          handleClose();
          setLoadConfig(!loadConfig);
        }
      })
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [reload, setReload] = React.useState(false);

  const [exportpopup, setExportPopupOpen] = React.useState(false);
  const dialogHandleCloseExport = () => {
    setExportPopupOpen(false);
    setExportRadioBtn({
      config_name: true,
      created_on: true,
    });
  };

  const [exportRadioBtn, setExportRadioBtn] = React.useState({
    config_name: true,
    created_on: true,
  });

  const [checkedState, setCheckedState] = React.useState([
    {
      name: "Configuration Name",
      checked: true,
    },
    {
      name: "Last Updated",
      checked: true,
    },
  ]);

  const [checkedState1, setCheckedState1] = React.useState([
    {
      name: "Configuration Name",
      checked: true,
    },

    {
      name: "Last Updated",
      checked: true,
    },
  ]);

  const handleCheckboxChange = (index: any, event: any) => {
    const values = [...checkedState1];
    values[index].checked = event.target.checked;
    setCheckedState1(values);
  };

  const handleSaveCheckedState = () => {
    let flag = false;
    checkedState1.forEach((item: any) => {
      if (item.checked) {
        flag = true;
      }
    });
    if (flag) {
      dialogHandleCloseCc();
      enqueueSnackbar("Columns Customised", { variant: "success" });
      const temp = [...checkedState] as any;
      checkedState1.forEach((item: any, index: number) => {
        temp[index].checked = item.checked
      })
      setCheckedState(temp);
    } else {
      enqueueSnackbar("Select at least one column", { variant: "error" });
    }
  };

  const handleCancelCheckedState = () => {
    const temp = [...checkedState1] as any;
    checkedState.forEach((item: any, index: number) => {
      temp[index].checked = item.checked
    })
    setCheckedState1(temp);
  };

  const checkBoxStyles = (theme: any) => ({
    root: {
      color: "#FFFFFF",

      "&$checked": {
        color: "#0089FF",
      },
    },
    checked: {},
  });

  const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleClickPaginate = (offset: number) => {
    setOffset(offset);
    setPage(offset / rowsPerPage);
  };
  const [offset, setOffset] = React.useState(0);

  const [backDrop, setbackDrop] = React.useState(false);
  const backDropClose = () => {
    setbackDrop(false);
  };
  const [configData, setConfigData] = React.useState([] as any);
  // const [uApplication, setApplication] = React.useState([] as any);
  // const [app, setApp] = useState("" as any);
  const [allConfigs, setAllConfigs] = useState([] as any);

  const { selectedApplication } = useApplicationContext();

  useEffect(() => {
    const tokenKey = "sso_token";
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    interface sessionObj {
      pm_s_id: string;
    }
    interface tokenObj {
      pm_s_token: string;
    }
    let pmSessionId: sessionObj = {
      pm_s_id: "",
    };
    let pmSessionToken: tokenObj = {
      pm_s_token: "",
    };
    pmSessionId =
      sessionStorage.getItem("pm_s_id") ||
      JSON.parse(JSON.stringify(pmSessionId));
    pmSessionToken =
      sessionStorage.getItem("pm_s_token") ||
      JSON.parse(JSON.stringify(pmSessionToken));
    setbackDrop(true);
    // const GETALLAPPS_API =
    //   API["GETALLAPPLS"] +
    //   `?pm_s_token=${pmSessionToken}&pm_s_id=${pmSessionId}`;
    const CONFIG_API =
      API["GETCONNCONFIG"] +
      `?pm_s_token=${pmSessionToken}&pm_s_id=${pmSessionId}`;
    Promise.all([
      // fetch(GETALLAPPS_API, {
      //   headers: {
      //     Authorization: `Bearer ${accessToken.access_token}`,
      //     'Ocp-Apim-Subscription-Key': `${API_KEY}`,
      //     'Ocp-Apim-Trace': `true`
      //   },
      // }),
      fetch(CONFIG_API, {
        headers: {
          Authorization: `Bearer ${accessToken.access_token}`,
          'Ocp-Apim-Subscription-Key': `${API_KEY}`,
          'Ocp-Apim-Trace': `true`
        },
      }),
    ])
      .then(function (responses) {
        return Promise.all(
          responses.map(function (response) {
            return response.json();
          })
        );
      })
      .then(function (response) {
        setbackDrop(false);
        if ((response && response[0].status === 401) && (response && arrayOfErrorsRefresh.includes(response[0].message.toLowerCase()))) {
          try {
            const tokenKey = "sso_token";
            setbackDrop(true);
            callXhrRequest().then(function (data) {
              sessionStorage.setItem(tokenKey, data);
              setbackDrop(false);
              setReload(!reload)
            }).catch(function (error) {
              user.triggerLogout();
              console.log('error', error)
            })
          } catch (error) {
            console.log(error);
          }
          return;
        }
        else if ((response && response[0].status === 401) && (response && arrayOfErrorsLogout.includes(response[0].message.toLowerCase()))) {
          try {
            user.triggerLogout();
          } catch (error) {
            console.log(error);
          }
          return;
        }
        // setApplication(response[0].applications);
        // setApp(response[0].applications[0].application_id);
        setAllConfigs(response[0].conn_configs);

        setConfigData(
          response[0].conn_configs.filter(function filterConfig(config: any) {
            return (
              parseInt(config.application_id) ===
              selectedApplication.application_id
            );
          }).sort(function compare_item(a: any, b: any) {
            if (a.config_name < b.config_name) {
              return -1;
            } else if (a.config_name > b.config_name) {
              return 1;
            } else {
              return 0;
            }
          })
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [isForm, loadConfig, API_KEY, reload]);

  useEffect(() => {
    const temp = [...allConfigs];
    setConfigData(
      temp.filter(function filterConfig(config: any) {
        return parseInt(config.application_id) === parseInt(selectedApplication.application_id);
      }).sort(function compare_item(a: any, b: any) {
        if (a.config_name < b.config_name) {
          return -1;
        } else if (a.config_name > b.config_name) {
          return 1;
        } else {
          return 0;
        }
      })
    );
  },[selectedApplication])

  // const handleSelectApplication = (event: any) => {
  //   setApp(event.target.value);
  //   const temp = [...allConfigs];
  //   setConfigData(
  //     temp.filter(function filterConfig(config: any) {
  //       return parseInt(config.application_id) === parseInt(event.target.value);
  //     }).sort(function compare_item(a: any, b: any) {
  //       if (a.config_name < b.config_name) {
  //         return -1;
  //       } else if (a.config_name > b.config_name) {
  //         return 1;
  //       } else {
  //         return 0;
  //       }
  //     })
  //   );
  // };

  const [comp, setComp] = React.useState(<div></div>);

  const [searchActive, setSearchActive] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleCloseSearch = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setSearchActive(false);
    setPage(offset / rowsPerPage);

  };
  const handleChangeSearch = (event: any) => {
    const target = event.target.value;
    setSearch(target);
    setPage(0);

    /* const temp=[...configData];
    const temp1=;
    setConfigData(temp1); */
  };

  const handleChangeExport = (event: any) => {
    setExportRadioBtn({
      ...exportRadioBtn,
      [event.target.name]: event.target.checked,
    });
  };
  const handleExport = () => {
    const exclData = [] as any;
    if (!(exportRadioBtn.config_name || exportRadioBtn.created_on)) {
      enqueueSnackbar("Please select at least one column to export", {
        variant: "error",
      });
    } else {
      configData.forEach((item: any) => {
        const temp:any = {};
        if (exportRadioBtn.config_name) {
          temp["ConfigurationName"] = item.config_name;
        }
        if (exportRadioBtn.created_on) {
          temp["LastUpdated"] = item.updated_on ? item.updated_on : item.created_on;
        }
        exclData.push(temp);
      });
      const {application_name} = selectedApplication;
      exportToExcel(exclData, "Connection Configurations", 9, application_name);
      setExportRadioBtn({
        config_name: true,
        created_on: true,
      });
      enqueueSnackbar("Export initiated", {
        variant: "success",
      });
      setExportPopupOpen(false);
    }
    setTimeout(() => {
      setReload(!reload);
    }, 10);
  };

  const sortAscending = () => {
    const temp = [...configData];
    temp.sort(function compare_item(a, b) {
      if (a.config_name < b.config_name) {
        return -1;
      } else if (a.config_name > b.config_name) {
        return 1;
      } else {
        return 0;
      }
    })
    setConfigData(temp);
    setSortAsc(true);

  }
  const sortDescending = () => {
    const temp = [...configData];
    temp.sort(function compare_item(a, b) {
      if (a.config_name > b.config_name) {
        return -1;
      } else if (a.config_name < b.config_name) {
        return 1;
      } else {
        return 0;
      }
    })
    setConfigData(temp);
    setSortAsc(false);

  }

  const sortDateAscending = () => {
    const temp = [...configData];
    temp.sort(function compare_item(a, b) {
      const tempA = a.last_updated ? a.last_updated : a.created_on
      const tempB = b.last_updated ? b.last_updated : b.created_on
      if (tempA < tempB) {
        return -1;
      } else if (tempA > tempB) {
        return 1;
      } else {
        return 0;
      }
    })
    setConfigData(temp);
    setDateSortAsc(true);

  }
  const sortDateDescending = () => {
    const temp = [...configData];
    temp.sort(function compare_item(a, b) {
      const tempA = a.last_updated ? a.last_updated : a.created_on
      const tempB = b.last_updated ? b.last_updated : b.created_on
      if (tempA > tempB) {
        return -1;
      } else if (tempA < tempB) {
        return 1;
      } else {
        return 0;
      }
    })
    setConfigData(temp);
    setDateSortAsc(false);

  }

  return (
    <React.Fragment>
      {!isForm && <div>
        <Grid
          container
          spacing={0}
          className={classes.alignCentre}
          style={{ marginLeft: "1.8em", marginBottom: "1.5em" }}
        >
          <ApplicationSelector />
        </Grid>
        {/* <Grid
          container
          spacing={0}
          className={classes.alignCentre}
          style={{ marginLeft: "1.5em", marginBottom: "1.5em" }}
        >
          <label className={classes.label}>Application: </label>
          <select
            className={classes.dropdown}
            onChange={handleSelectApplication}
            value={app}
          >
            {uApplication.map((option: any) => (
              <option value={option.application_id}>
                {option.application_name}
              </option>
            ))}
          </select>
        </Grid> */}
        <div
          className={classes.gridRoot}
          style={{ marginLeft: "1.5em", marginRight: "1.5em" }}
        >
          <Grid container direction="column" spacing={0}>
            <Grid
              container
              direction="row"
              xs={12}
              sm={6}
              style={{ marginTop: "1em", marginBottom: "1em" }}
              alignItems="center"
            >
              <BreadcumbComponent route='connectionConfig'/>
            </Grid>
            <Divider variant="fullWidth" style={{ color: "#33333F" }} />
            <Grid container direction="row" alignItems="center">
              <Grid item xs={12} sm={6}>
                <List classes={{ root: classes.flexContainer }} dense={true}>
                  <ListItem classes={{ root: classes.listItem }}>
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      primary="Total Configurations"
                      primaryTypographyProps={{
                        style: { display: "inline-block" },
                      }}
                      secondary={<span>{configData.length}</span>}
                      secondaryTypographyProps={{
                        style: {
                          display: "inline-block",
                          color: "#FFFFFF",
                          fontWeight: "bold",
                          marginLeft: "5px",
                          fontSize: "16px",
                        },
                      }}
                    />
                  </ListItem>
                  <ListItem
                    classes={{ root: classes.listItem }}
                    className={classes.listButtonWrap}
                  >
                    <Button
                      variant="text"
                      color="inherit"
                      className={classes.listButton}
                      startIcon={<img alt="Export Logo" src={exportLogo}></img>}
                      onClick={() => setExportPopupOpen(true)}
                    >
                      Export
                    </Button>
                  </ListItem>
                  <ListItem
                    classes={{ root: classes.listItem }}
                    className={classes.listButtonWrap}
                  >
                    <Button
                      variant="text"
                      color="inherit"
                      className={classes.listButton}
                      startIcon={<ColumnIcon style={{ color: "#7C7C94" }} />}
                      onClick={dialogHandleClickOpenCc}
                    >
                      Customise Columns
                    </Button>

                    <Dialog
                      classes={{ paper: classes.dialogRoot }}
                      onClose={dialogHandleCloseCc}
                      aria-labelledby="customized-dialog-title"
                      open={openDialogCc}
                    >
                      <DialogTitle
                        id="customized-dialog-title"
                        onClose={dialogHandleCloseCc}
                      >
                        Customise Columns
                      </DialogTitle>
                      <DialogContent>
                        <FormGroup row>
                          <Grid container xs={12}>
                            {checkedState1.map((box, index) => (
                              <Grid item xs={5} style={{ marginLeft: "1em" }}>
                                <FormControlLabel
                                  control={
                                    <CustomCheckbox
                                      checked={checkedState1[index].checked}
                                      onChange={(event) =>
                                        handleCheckboxChange(index, event)
                                      }
                                      name={checkedState1[index].name}
                                    />
                                  }
                                  label={checkedState1[index].name}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </FormGroup>
                      </DialogContent>
                      <Divider
                        variant="middle"
                        classes={{ middle: classes.dividerColor }}
                      />

                      <DialogActions>
                        <Button
                          onClick={() => {
                            dialogHandleCloseCc();
                            handleCancelCheckedState();
                          }}
                          variant="outlined"
                          className="dcBtDelete"
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={() => {
                            handleSaveCheckedState();
                          }}
                          variant="contained"
                          className="dcBtEdit"
                        >
                          Save
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </ListItem>

                  <ListItem
                    classes={{ root: classes.listItem }}
                    className={classes.listButtonWrap}
                  >
                    {/* <Button
                      variant="text"
                      color="inherit"
                      className={classes.listButton}
                      startIcon={<SearchIcon style={{ color: '#7C7C94' }} />}
                    >
                      Search
                  </Button> */}
                    <ClickAwayListener onClickAway={handleCloseSearch}>
                      {searchActive || search !== "" ? (
                        <CssTextField
                          InputProps={{
                            disableUnderline: true,
                          }}
                          InputLabelProps={{
                            shrink: false,
                            className: classes.labels,
                          }}
                          autoComplete="off"
                          value={search}
                          onChange={(event) => handleChangeSearch(event)}
                          id="filled-search"
                          type="search"
                          variant="filled"
                        />
                      ) : (
                        <Button
                          variant="text"
                          color="inherit"
                          className={classes.listButton}
                          startIcon={
                            <SearchIcon style={{ color: "#7C7C94" }} />
                          }
                          onClick={() => setSearchActive(true)}
                        >
                          Search
                        </Button>
                      )}
                    </ClickAwayListener>

                    {/* <ListItemText primary="Search" classes={{ primary: classes.listItemText}} /> */}
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={6} alignItems="center">
                <div className={classes.rightSec}>
                  <Button
                    variant="contained"
                    color="inherit"
                    className={classes.listButton}
                    endIcon={<AddIcon />}
                    size="large"
                    onClick={() => {
                      setComp(
                        <AddConnConfig
                          returnHome={() => {
                            setIsForm(false);
                          }}
                        ></AddConnConfig>
                      );
                      setIsForm(true);
                      handleClose();
                    }}
                  >
                    Add New Configuration
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Backdrop
          className={classes.backdrop}
          open={backDrop}
          onClick={backDropClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Dialog
          classes={{ paper: classes.dialogRoot }}
          onClose={dialogHandleCloseExport}
          aria-labelledby="customized-dialog-title"
          open={exportpopup}
        >
          <Grid container direction="row" alignItems="center">
            <DialogTitle
              id="customized-dialog-title"
              onClose={dialogHandleCloseExport}
            >
              Select columns to export
            </DialogTitle>
          </Grid>
          <DialogContent>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0089ff" }}
                      color="primary"
                      checked={exportRadioBtn.config_name}
                      onChange={handleChangeExport}
                      name="config_name"
                    />
                  }
                  style={{ color: "white" }}
                  label="Configuration Name"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0089ff" }}
                      color="primary"
                      checked={exportRadioBtn.created_on}
                      onChange={handleChangeExport}
                      name="created_on"
                    />
                  }
                  style={{ color: "white" }}
                  label="Last Updated"
                />
              </FormGroup>
            </FormControl>
          </DialogContent>
          <Divider
            variant="middle"
            classes={{ middle: classes.dividerColor }}
          />
          <DialogActions>
            <Button
              onClick={dialogHandleCloseExport}
              variant="outlined"
              className="dcBtDelete"
            >
              Cancel
            </Button>
            <Button
              onClick={handleExport}
              variant="contained"
              className="dcBtEdit"
            >
              Export
            </Button>
          </DialogActions>
        </Dialog>

        <TableContainer component={Paper} className={classes.tableWrapper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                {checkedState[0].checked ? (
                  <StyledTableCell>
                    <Grid container direction='row'>
                      <Typography style={{ fontWeight: 'bold' }}>Configuration Name</Typography>
                      {sortAsc ? <ArrowDropDownIcon onClick={sortDescending}></ArrowDropDownIcon> : <ArrowDropUpIcon onClick={sortAscending}></ArrowDropUpIcon>}
                    </Grid>
                  </StyledTableCell>
                ) : (
                  <div></div>
                )}
                {checkedState[1].checked ? (
                  <StyledTableCell>
                    <Grid container direction='row'>
                      <Typography style={{ fontWeight: 'bold' }}>Last Updated</Typography>
                      {sortDateAsc ? <ArrowDropDownIcon onClick={sortDateDescending}></ArrowDropDownIcon> : <ArrowDropUpIcon onClick={sortDateAscending}></ArrowDropUpIcon>}
                    </Grid>
                  </StyledTableCell>
                ) : (
                  <div></div>
                )}
                {/* <StyledTableCell>Number of Devices</StyledTableCell> */}
                <StyledTableCell>&nbsp;</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {configData.filter((item: any) =>
              ((checkedState[0].checked ? item.config_name
                .toLowerCase()
                .includes(search.toLowerCase()) : false) ||
                (checkedState[1].checked ? item.updated_on ? item.updated_on
                  .toLowerCase()
                  .includes(search.toLowerCase()) : item.created_on
                    .toLowerCase()
                    .includes(search.toLowerCase()) : false)))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, index: number) => (
                  <StyledTableRow key={row._id}>
                    {checkedState[0].checked ? (
                      <StyledTableCell>{row.config_name}</StyledTableCell>
                    ) : (
                      <div></div>
                    )}
                    {checkedState[1].checked ? (
                      <StyledTableCell>
                        {row.updated_on ? row.updated_on : row.created_on}
                      </StyledTableCell>
                    ) : (
                      <div></div>
                    )}
                    {/* <StyledTableCell>{row.count}</StyledTableCell> */}
                    <StyledTableCell>
                      <IconButton
                        onClick={(event) => handleClick(event, row)}
                        classes={{ root: classes.menuButtonRoot }}
                      >
                        <MoreVertIcon color="secondary" />
                      </IconButton>
                      <Menu
                        id="user-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        classes={{ paper: classes.menuRoot }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuItem
                          className={classes.menuitem}
                          onClick={() => {
                            dialogHandleClickOpenView();
                            handleClose();
                          }}
                        >
                          <VisibilityIcon fontSize="small"></VisibilityIcon>
                          View Details
                        </MenuItem>

                        <MenuItem
                          className={classes.menuitem}
                          onClick={() => {
                            setComp(
                              <EditConnConfig
                                onEditConfig={() => {
                                  setIsForm(false);
                                }}
                                editItem={selectedConfig}
                              ></EditConnConfig>
                            );
                            setIsForm(true);
                            handleClose();
                          }}
                        >
                          <EditIcon></EditIcon>
                          Edit
                        </MenuItem>
                        <MenuItem
                          className={classes.menuitem}
                          onClick={() => {
                            dialogHandleClickOpenDelete();
                            handleClose();
                          }}
                        >
                          <DeleteOutlinedIcon></DeleteOutlinedIcon>
                          Delete
                        </MenuItem>
                      </Menu>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
            {/*             {configData.filter((item: any) => item.config_name.toLowerCase().includes(search.toLowerCase())).length > rowsPerPage ?
             */}{" "}
            <TableFooter className={classes.tblfooter}>
            </TableFooter>
          </Table>
        </TableContainer>
        <Grid container xs={12} direction='row' alignItems='center' justifyContent='space-between' style={{ padding: '2em' }}>
          <Grid xs={4} item container direction='row' alignItems='center'>
            <Typography className={classes.paginateView}>View: </Typography>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={configData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage={"Configurations per page :"}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              className={classes.tblpaginate}
            />
            <Typography className={classes.paginateUser}>
              Configurations per page
            </Typography>
          </Grid>

          <Pagination
            limit={rowsPerPage}
            offset={offset}
            onClick={(e, offset) => handleClickPaginate(offset)}
            total={configData.length}
            className={classes.tblpaginatenumber}
            currentPageColor="secondary"
            otherPageColor="primary"
          />
        </Grid>



        <Dialog
          classes={{ paper: classes.dialogRootSmall }}
          onClose={dialogHandleCloseDelete}
          aria-labelledby="customized-dialog-title"
          open={openDialogDelete}
        >
          <Grid container direction="row" alignItems="center">
            <img
              style={{ marginLeft: "1em" }}
              alt="Delete Logo"
              src={deleteLogo}
            ></img>
            <DialogTitle
              id="customized-dialog-title"
              onClose={dialogHandleCloseDelete}
            >
              Delete Configuration
            </DialogTitle>
          </Grid>
          <DialogContent>
            <Typography
              style={{
                fontFamily: font_family,
                fontSize: "14px",
                lineHeight: "16px",
                display: "flex",
                alignItems: "center",
                margin: "1em",
                color: "#FFFFFF",
              }}
            >
              Are you sure you want to delete this Configuration? This
              Configuration cannot be recovered.
            </Typography>
          </DialogContent>
          <Divider
            variant="middle"
            classes={{ middle: classes.dividerColor }}
          />

          <DialogActions>
            <Button
              onClick={dialogHandleCloseDelete}
              variant="outlined"
              className="dcBtDelete"
            >
              Cancel
            </Button>
            <Button
              onClick={() => saveDelete()}
              variant="contained"
              className="dcBtEdit"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          classes={{ paper: classes.dialogRoot }}
          onClose={dialogHandleCloseView}
          aria-labelledby="customized-dialog-title"
          open={openDialogView}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={dialogHandleCloseView}
          >
            View Configuration Details
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={0} className="dcOuter">
              <Grid item xs={4}>
                <Typography className="dcTitle">Configuration Name</Typography>
                <Typography className="dcFieldValue">
                  {selectedConfig.config_name}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className="dcTitle">
                  Configuration Description
                </Typography>
                <Typography className="dcFieldValue">
                  {selectedConfig.config_desc}
                </Typography>
              </Grid>
            </Grid>

            {/* <Grid container spacing={0} className="dcOuter">

              <Grid item xs={4}>
                <Typography className="dcTitle">API URL</Typography>
                <Typography className="dcFieldValue">{selectedConfig.metadata.api_url}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className="dcTitle">Bearer Token</Typography>
                <Typography className="dcFieldValue">{selectedConfig.metadata.bearer_token}</Typography>
              </Grid>
            </Grid> */}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                dialogHandleCloseView();
                dialogHandleClickOpenDelete();
              }}
              variant="outlined"
              className="dcBtDelete"
            >
              Delete
            </Button>
            <Button
              onClick={() => {
                setComp(
                  <EditConnConfig
                    onEditConfig={() => {
                      setIsForm(false);
                    }}
                    editItem={selectedConfig}
                  ></EditConnConfig>
                );
                setIsForm(true);
                handleClose();
                dialogHandleCloseView();
              }}
              variant="contained"
              className="dcBtEdit"
            >
              Edit
            </Button>
          </DialogActions>
        </Dialog>
      </div>}
      {isForm && <div>{comp}</div>}
    </React.Fragment>
  );
}
export default function Configs() {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
    >
      <ConfigsWrapper />
    </SnackbarProvider>
  );
}
