import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  CustomButton,
  LoadingIndicator,
  SubmitButton,
} from "../../../../UiComponents";
import {
  TAG_TYPE,
  letterNumberRegXWithSpace,
} from "../../../../../utils/constant";
import useApi from "../../../../../hooks/useApi";
import readError from "../../../../../utils/readError";
import { hasDuplicateColumnEntries, isValidColumnFormat } from "../utils";
import { CONFIG_PAGE } from "../../types";
import useStyles from "./Submit.styles";

const MAX_TEXT_LIMIT = 50;

const hasDuplicateOptions = (optionsArray: string[]) => {
  const loweredOptions = optionsArray
    .filter((s) => !!s)
    .map((s) => s.toLowerCase());
  return new Set(loweredOptions).size !== loweredOptions.length;
};

const validateDropdownTag = (tagName: string, tagData: any) => {
  const optionsArray: string[] = tagData.options.split("|");

  if (tagData.data_type === "number") {
    if (optionsArray.some((op) => !op)) {
      throw new Error(
        `Invalid options given to tag '${tagName}'. Options should not be empty`
      );
    }
    if (optionsArray.some((op: string) => isNaN(+op))) {
      throw new Error(
        `Invalid options given to tag '${tagName}'. Data type is number.`
      );
    }
  }

  if (hasDuplicateOptions(optionsArray)) {
    throw new Error(`Duplicate options entered for tag '${tagName}'.`);
  }
  if (tagData.options && !isValidOptionFormat(tagData.options)) {
    throw new Error(`Invalid options entered for tag '${tagName}'.`);
  }
};

const checkForDuplicateColumnDeclaration = (tags: any): void => {
  const tabularTags = Object.keys(tags)
    .filter((tagName: string) => tags[tagName]?.type === TAG_TYPE.Tabular)
    .map((tagName: string) => ({ tagName, ...tags[tagName] }));

  const newlyAddedTabularTags = tabularTags.filter((tag: any) => tag._isNew);

  newlyAddedTabularTags.forEach((tag) => {
    const columnsInTag = tag.value.split("|");

    const columnNamesTaken = tabularTags
      .filter((t: any) => t.tagName !== tag.tagName)
      .map((t) => t.value?.split("|"))
      .flatMap((t) => t);

    columnsInTag.forEach((column: any) => {
      if (columnNamesTaken.some((cn) => cn === column)) {
        throw new Error(`Column name '${column}' is already in use.`);
      }
    });
  });
};

const isValidOptionFormat = (options: string) => {
  const optionsArray: string[] = options.split("|");
  return !optionsArray.some((op) => !op);
};

const hasOptionsRepeated = (options: string) => {
  const optionsArray: string[] = options.split("|");
  return new Set(optionsArray).size !== optionsArray.length;
};

const validateDropdownSpecsValues = (dropdownSpecs: any) => {
  const tabularTagsWithDropdownSpecs = Object.keys(dropdownSpecs);
  tabularTagsWithDropdownSpecs.forEach((tabularTag) => {
    const dropdownColumns = Object.keys(dropdownSpecs[tabularTag]);
    dropdownColumns.forEach((column) => {
      const options = dropdownSpecs[tabularTag][column];
      if (!isValidOptionFormat(options)) {
        throw new Error(
          `Invalid dropdown specification for ${tabularTag} tag (${column} column)`
        );
      }
      if (hasOptionsRepeated(options)) {
        throw new Error(
          `Options repeated in dropdown specification for ${tabularTag} tag (${column} column)`
        );
      }
    });
  });
};

//TODO: this can be moved to utilities
const getMissingElements = (parentArray: string[], childArray: string[]) =>
  childArray.filter((val) => parentArray.indexOf(val) < 0);

const validateDropdownspecsMapping = (tags: any, dropdownSpecs: any) => {
  for (const dropdownSpecTagName in dropdownSpecs) {
    if (!tags[dropdownSpecTagName]) {
      throw new Error(
        `Tabular tag '${dropdownSpecTagName}' not found. Please remove dropdown specification for it`
      );
    }

    const mappedColumns = Object.keys(dropdownSpecs[dropdownSpecTagName]);
    const tabularTagColumns = (tags[dropdownSpecTagName]?.value || "").split(
      "|"
    );
    const missingColumns = getMissingElements(tabularTagColumns, mappedColumns);
    if (missingColumns.length > 0) {
      throw new Error(
        `Columns ${missingColumns.join(
          ","
        )} not found in tabular tag '${dropdownSpecTagName}'`
      );
    }
  }
};

const validateDropdownSpec = (tags: any, dropdownSpecs: any) => {
  validateDropdownspecsMapping(tags, dropdownSpecs);
  validateDropdownSpecsValues(dropdownSpecs);
};

const validateTextTag = (tagName: string, tagData: any) => {
  const isNumber = tagData.data_type === "number";

  if (isNumber) {
    if (!tagData.value) {
      throw new Error(
        `Tag default value cannot be empty for 'number' tags. Please enter zero for '${tagName}'`
      );
    }
    if (isNaN(tagData.value)) {
      throw new Error(
        `Invalid value given to tag '${tagName}'. Data type is number.`
      );
    }
  }
};

const validateTabularTag = (tagName: string, tagData: any) => {
  if (!tagData._isNew) {
    return;
  }

  if (!tagData.value) {
    throw new Error(`Please enter columns for tabular tag '${tagName}'`);
  }
  if (!isValidColumnFormat(tagData.value)) {
    throw new Error(
      `Column entries are not valid for tabular tag '${tagName}'`
    );
  }
  if (hasDuplicateColumnEntries(tagData.value)) {
    throw new Error(`Duplicate columns found for tabular tag '${tagName}'`);
  }
};

const validateTags = (tags: any) => {
  for (const tagName in tags) {
    const tagData = tags[tagName];
    if (tagData.type === TAG_TYPE.Dropdown) {
      validateDropdownTag(tagName, tagData);
    } else if (tagData.type === TAG_TYPE.Text) {
      validateTextTag(tagName, tagData);
    } else if (tagData.type === TAG_TYPE.Tabular) {
      validateTabularTag(tagName, tagData);
    }
  }
};

const validateProperties = (properties: any) => {
  for (const property in properties) {
    const propertyValue = properties[property] || "";
    if (
      !letterNumberRegXWithSpace.test(propertyValue) ||
      propertyValue.length > MAX_TEXT_LIMIT
    ) {
      throw new Error(
        `Invalid value for property ${property} (only 0-9,A-Z,a-z,_,- allowed upto 50 characters)`
      );
    }
  }
};

const validateBasicFields = (configData: any) => {
  const {
    application_id,
    config_name,
    device_type,
    edge_type,
    auth_type,
    config_desc,
  } = configData;

  if (!application_id) {
    throw new Error("Please select Application");
  }
  if (!config_name) {
    throw new Error("Please enter Configuration name");
  }
  if (
    !(letterNumberRegXWithSpace.test(config_name) && config_name.length < 50)
  ) {
    throw new Error(
      "Invalid Configuration name (only 0-9,A-Z,a-z,_,- allowed upto 50 characters)"
    );
  }
  if (!device_type) {
    throw new Error("Please select Device type");
  }
  if (!edge_type) {
    throw new Error("Please select Edge type");
  }
  if (!auth_type) {
    throw new Error("Please select Auth type");
  }
  if (!config_desc) {
    throw new Error("Please enter Configuration description.");
  }
  if (
    !(letterNumberRegXWithSpace.test(config_desc) && config_desc?.length < 50)
  ) {
    throw new Error(
      "Invalid Configuration Description (only 0-9,A-Z,a-z,_,- allowed upto 50 characters)"
    );
  }
};

const validateData = (configData: any) => {
  const { tags, properties, dropdown } = configData;

  validateBasicFields(configData);
  validateTags(tags);
  checkForDuplicateColumnDeclaration(tags);
  validateDropdownSpec(tags, dropdown);
  validateProperties(properties);
};

const prepareTags = (tags: any, isEdit: boolean) => {
  const updatedTags: any = {};
  for (const tag in tags) {
    const { updatedVisibility, _isNew, device_metadata_options_id, ...rest } =
      tags[tag];
    updatedTags[tag] = {
      ...rest,
      ...(updatedVisibility !== undefined
        ? { is_visible_as_column: updatedVisibility }
        : {}),
      ...(isEdit ? { _isNew, device_metadata_options_id } : {}),
    };
  }

  return updatedTags;
};

interface ISubmitProps {
  configData: any;
  page: CONFIG_PAGE;
  onDone: () => void;
}

const getEndPoints = (configData: any, isEdit: boolean) => {
  const { application_id } = configData;
  if (!isEdit) {
    return `/applications/${application_id}/device-configs`;
  }
  const { device_config_id } = configData;
  return `/applications/${application_id}/device-configs/${device_config_id}`;
};

const Submit: React.FC<ISubmitProps> = ({ configData, page, onDone }) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const isEdit = page === CONFIG_PAGE.EDIT_CONFIG;

  const {
    data: saveApiResponse,
    status: saveStatus,
    trigger: submitData,
  } = useApi(getEndPoints(configData, isEdit), {
    method: isEdit ? "PUT" : "POST",
    deferred: true,
    includePMSCredentialsInRequestBody: true,
    onError: (error: any) => {
      const errorText =
        error?.response?.data?.data ||
        `An error occurred while ${
          isEdit ? "updating" : "saving"
        } configuration`;

      enqueueSnackbar(errorText, {
        variant: "error",
      });
    },
    doNotExtractError: true,
    // mock: { fetcher: () => ({ message: "success" }) },
  });

  useEffect(() => {
    if ((saveApiResponse?.message || "").toLowerCase() === "success") {
      enqueueSnackbar(`Configuration ${isEdit ? "Changed" : "Saved"}`, {
        variant: "success",
      });
      onDone();
    }
  }, [saveApiResponse, saveStatus.error]);

  return (
    <div className={classes.buttonWrapper}>
      {saveStatus.pending && <LoadingIndicator />}
      <CustomButton variant="outlined-white" onClick={onDone}>
        Cancel
      </CustomButton>
      <SubmitButton
        disabled={saveStatus.pending}
        onClick={() => {
          try {
            validateData(configData);
          } catch (error) {
            const errorMessage = readError(error);
            return enqueueSnackbar(errorMessage, { variant: "error" });
          }
          submitData({
            device_config: {
              ...configData,
              tags: prepareTags(configData.tags, isEdit),
            },
          });
        }}
      >
        Save Configuration
      </SubmitButton>
    </div>
  );
};

export default Submit;
