import React, { useEffect, useState } from 'react'
import { LoadingIndicator, Modal } from '../../UiComponents';
import { createStyles, makeStyles } from '@material-ui/styles';
import { DialogActions, Grid, Typography } from '@material-ui/core';
import { useApplicationContext } from '../../../contexts/ApplicationContext';
import useApi from '../../../hooks/useApi';
import { addMinutes, format } from 'date-fns';
import { OPERATION_STATUS_LABEL, OperationStatus } from '../../../utils/constant';

interface viewAuditDetailsProps {
  onDone?: () => void;
  record?: any;
}
const useStyles = makeStyles(() =>
  createStyles({
    infoWrapper: {
      backgroundColor: '#2B2B36',
      minHeight: 200,
      minWidth: 400,
      padding: "30px 20px",
      borderRadius: 4,
    },
    title: {
      fontSize: "19px",
      lineHeight: "19px",
      color: "#FFFFFF",
      fontWeight: "bold",
      // marginTop: "20px",
    },
    fieldValue: {
      fontSize: "14px",
      lineHeight: "16px",
      marginTop: "10px",
      color: "#FFFFFF",
      overflowWrap: "anywhere",
      paddingRight: ".5em",
    }
  }))


function ViewAuditLog({ onDone = () => true, record }: viewAuditDetailsProps) {
  const [appliactionNameMap, setApplicationNameMap]: any = useState({});

  const classes = useStyles();
  const { selectedApplication, applications }: any = useApplicationContext();
  const viewDetailsEndpoint = `/applications/${selectedApplication.application_id}/audit-msgs/${record?.id}`
  const { data, status } = useApi(viewDetailsEndpoint, {
    method: "GET"
  })

  useEffect(() => {
    if (applications && applications.length) {
      const appMap: any = {};
      applications.forEach(({ application_id, application_name }: any) => {
        appMap[application_id] = application_name;
      });
      setApplicationNameMap(appMap);
    }
  }, [applications])
  const logDetails = data?.audit_msg || {};
  let dateStr = "";
  if (!status.pending && logDetails?.activity_datetime) {
    const utcTime = new Date(logDetails?.activity_datetime);
    const date = addMinutes(utcTime, utcTime.getTimezoneOffset() * -1)
    dateStr = format(date, "yyyy-MM-dd HH:mm:ss")
  }
  const operation: OperationStatus = logDetails?.operation;
  return (
    <Modal title="Audit Log Details" contentWrapperStyle={{ border: 'none' }}
      open={true} onClose={onDone} >
      {status.pending && <LoadingIndicator />}
      <div className={classes.infoWrapper}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Typography className={classes.title} >
              Application
            </Typography>
            <Typography className={classes.fieldValue} >
              {logDetails.application_id && appliactionNameMap[logDetails.application_id]}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.title} >
              Activity Date Time
            </Typography>
            <Typography className={classes.fieldValue} >
              {dateStr}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.title} >
              Requested Action
            </Typography>
            <Typography className={classes.fieldValue} >
              {logDetails?.operation && OPERATION_STATUS_LABEL[operation]}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.title} >
              Request Status
            </Typography>
            <Typography className={classes.fieldValue} >
              {logDetails?.status}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.title} >
              Requested By
            </Typography>
            <Typography className={classes.fieldValue} >
              {logDetails?.requested_by}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: 10 }} className={classes.infoWrapper}>
        <Typography className={classes.title} >
          Data
        </Typography>
        <pre>
          {JSON.stringify(logDetails?.metadata, null, 2)}
        </pre>
      </div>
      <DialogActions>
        <Grid item xs={12}>
          {/* to do */}
        </Grid>
      </DialogActions>
    </Modal>
  )
}

export default ViewAuditLog