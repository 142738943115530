import React, { useEffect, useState, useCallback } from "react";
import { Grid } from "@material-ui/core";

import PaginatedDataTable, { IPaginationDetails } from "../PaginatedDataTable";
import LoadingIndicator from "../LoadingIndicator";
import useApi from "../../../hooks/useApi";

import {
  MultiFilterButton,
  MultiFilterChipRenderer,
  MultiFilterContextProvider,
} from "../MultiFilter";
import { IFilter } from "../MultiFilter/types";
import { IColumn } from "../DataTable/types";

//synthentic columns will be used to load additional template columns
//TODO: If needed this can be injected as a prop. Its ok for now as we have only 'action' column as synthetic.
const addSyntheticColumns = (data: any = [], dataLookupKey: string) => {
  if (!data[dataLookupKey] || data[dataLookupKey].length === 0) {
    return { ...data, [dataLookupKey]: [] };
  }

  const updatedRecordSet = data[dataLookupKey].map((record: any) => ({
    ...record,
    action: "",
  }));
  return { ...data, [dataLookupKey]: updatedRecordSet };
};

const getFilterableColumns = (columns: any[]) => {
  return columns.filter(({ searchable }) => searchable);
};

const updateSortingSequence = (
  sortingSequenceArray: any[],
  columnName: string,
  order: "asc" | "desc"
) => {
  //remove the column from current sorting sequence
  const filteredSortingSequence = sortingSequenceArray.filter(
    ({ name }) => name !== columnName
  );
  //append the column on top of the sequence
  return [{ name: columnName, order }, ...filteredSortingSequence];
};

const DEFAULT_PAGE_SETTING = {
  limit: 10,
  page_num: 1,
};

/*TODO: This one is device page specific. This somewhat ruins the purity of component.
Make this generic, if not possible - move this to consumer component
*/
const getDeviceMetaDataOptionsId = (columns: any, columnName: string) => {
  const { device_metadata_options_id } = columns.find(
    ({ id }: any) => id === columnName
  ) || { device_metadata_options_id: null };

  return device_metadata_options_id;
};

const clearSavedFilters = () => {
  window.sessionStorage.removeItem("savedDeviceManagementFilters");
};

const getDefaultFilters: any = (columns: IColumn[]) => {
  const defaultFilterColumns = columns.filter(
    ({ defaultFilterValue }) => !!defaultFilterValue
  );

  const defaultFilters = defaultFilterColumns.map((column: IColumn) => {
    return {
      filterType: column.id,
      filterValue: column.defaultFilterValue,
    };
  });
  return defaultFilters;
};

const getSavedFilters = (filterPreserveKey: string = "defaultKey") => {
  let savedFilters: any = [];
  if (!filterPreserveKey) {
    return savedFilters;
  }
  try {
    savedFilters =
      JSON.parse(
        window.sessionStorage.getItem("savedDeviceManagementFilters") ||
          `{"defaultKey":[]}`
      )[filterPreserveKey] || [];
    if (savedFilters.length === 0) {
      clearSavedFilters();
    }
  } catch (ex) {
    console.warn("Error occured while reading filter", ex);
  } finally {
    return savedFilters;
  }
};

const saveFilters = (filterPreserveKey: string, filters: any) => {
  if (!filterPreserveKey) {
    return;
  }
  window.sessionStorage.setItem(
    "savedDeviceManagementFilters",
    JSON.stringify({ [filterPreserveKey]: filters })
  );
};

//see how we built columnSchema to understand translation
const translateFilterValue = (filter: IFilter, columns: any[]) => {
  const { translator = (value: string) => value } = columns.find(
    ({ id }: any) => id === filter.filterType
  );
  return translator(filter.filterValue);
};

const mapFilters = (filters: IFilter[], columns: any[]) => {
  return filters
    .filter(
      //this is required for safe useApi initialization
      (ft) => columns.find(({ id }: any) => id === ft.filterType) //remove invalid filters
    )
    .map((filter: IFilter) => {
      return {
        name: filter.filterType,
        value:
          typeof filter.filterValue === "string"
            ? translateFilterValue(filter, columns)
            : filter.filterValue,
        device_metadata_options_id: filter.filterId,
      };
    });
};

const hasUnsafeFilters = (filters: IFilter[], columns: any[]) => {
  const activeColumnIds = columns.map(({ id }: any) => id);
  return filters.some(
    ({ filterType }) => !activeColumnIds.includes(filterType)
  );
};

interface ISortingSequence {
  name: string;
  order: "asc" | "desc";
}

interface IMultifilteredDataTableProps {
  selectedApplication: any;
  columns: IColumn[];
  endPoint: string;
  gridController?: Function;
  filterPreserveKey?: string;
  externalFilters?: IFilter[];
  disabled?: boolean;
  dataLookupKey?: string;
  totalRecordsLookupKey?: string;
  disableMultiSort?: boolean;
  defaultSortingSequence?: ISortingSequence[];
  clientApi?: any;
}

const MultifilteredDataTable: React.FC<IMultifilteredDataTableProps> = ({
  selectedApplication,
  columns,
  endPoint,
  gridController = () => null,
  filterPreserveKey = "",
  externalFilters = [],
  disabled = false,
  dataLookupKey = "devices",
  totalRecordsLookupKey = "total_devices",
  disableMultiSort = false,
  defaultSortingSequence = [],
  clientApi,
}) => {
  const [activeFilters, setActiveFiltersInternal] = useState<IFilter[]>([
    ...getDefaultFilters(columns),
    ...getSavedFilters(filterPreserveKey),
  ]);

  const [sortingSequence, setSortingSequence] = useState<ISortingSequence[]>(
    defaultSortingSequence
  );

  const [hasError, setHasError] = useState(false);

  const getSortingInfo = () => {
    //if multiSort is disabled, the component will still track the click order. We will send the latest one registered to backend
    if (disableMultiSort) {
      //using legacy sort
      return sortingSequence.length > 0
        ? {
            sort_by_field: sortingSequence[0].name,
            sort_order: sortingSequence[0].order,
          }
        : {};
    }
    return {
      sort: sortingSequence
        .filter((sq) => columns.find(({ id }: any) => id === sq.name)) //remove invalid columns
        .map((sq) => {
          return {
            device_metadata_options_id: getDeviceMetaDataOptionsId(
              columns,
              sq.name
            ),
            ...sq,
          };
        }),
    };
  };

  const setActiveFilters = useCallback(
    (filters: IFilter[]) => {
      setActiveFiltersInternal(filters);
      saveFilters(filterPreserveKey, filters);
    },
    [filterPreserveKey]
  );

  const {
    data,
    status,
    trigger: refetchGridData,
  } = useApi(
    `/applications/${selectedApplication.application_id}/${endPoint}`,
    {
      data: {
        ...DEFAULT_PAGE_SETTING,
        filter: mapFilters([...externalFilters, ...activeFilters], columns),
        ...getSortingInfo(),
      },
      method: "GET",
      responseDataFormatter: (data: any) =>
        addSyntheticColumns(data, dataLookupKey),
      mock: clientApi ? { fetcher: clientApi, timeout: 10 } : undefined,
    }
  );

  useEffect(() => {
    if (hasUnsafeFilters(activeFilters, columns)) {
      const activeColumnIds = columns.map(({ id }: any) => id);
      const safeFilters = activeFilters.filter(({ filterType }: any) =>
        activeColumnIds.includes(filterType)
      );
      //This will update observable hash and trigger a data fetch.
      setActiveFilters(safeFilters);
    }
  }, [columns, activeFilters, setActiveFilters]);

  useEffect(() => {
    setHasError(!!status.error);
  }, [status.pending, status.error]);

  const totalRecords = data ? data.summary[totalRecordsLookupKey] : 0;

  return (
    <>
      <MultiFilterContextProvider
        availableFilters={getFilterableColumns(columns)}
        activeFilters={activeFilters}
        onFilterChange={(activeFilters: any) => setActiveFilters(activeFilters)}
      >
        {gridController({
          data,
          activeFilters,
          externalFilters,
          sortingSequence,
          setActiveFilters,
          gridControls: <MultiFilterButton />,
        })}

        <Grid style={{ margin: "1.5em" }}>
          <MultiFilterChipRenderer />
        </Grid>
      </MultiFilterContextProvider>

      {status.pending && <LoadingIndicator />}
      {data && (
        <PaginatedDataTable
          totalRecords={totalRecords}
          columns={columns}
          values={hasError ? [] : data[dataLookupKey]}
          observeForRefresh={[
            JSON.stringify({ activeFilters, columns, externalFilters }),
          ]}
          onSort={(columnName, order) => {
            const updatedSortingSequence = updateSortingSequence(
              sortingSequence,
              columnName,
              order
            );
            setSortingSequence(updatedSortingSequence);
          }}
          dataFetcher={(paginationDetails: IPaginationDetails) => {
            if (disabled) {
              /*TODO: A better name? What about 'suspended'/'paused'? As this 
              temporarily disables network activity*/
              return;
            }
            //If there are filters that are no longer present in column, cancel data fetch.
            //The useEffect hook will detect and correct the filters followed by a grid refresh
            if (hasUnsafeFilters(activeFilters, columns)) {
              console.log("column schema changed. Fetch canceled"); //TODO: remove once stable
              return;
            }
            const { pageNumber: page_num, rowsPerPage: limit } =
              paginationDetails;
            refetchGridData({
              page_num,
              limit,
              filter: mapFilters(
                [...externalFilters, ...activeFilters],
                columns
              ),
              ...getSortingInfo(),
            });
          }}
        />
      )}
    </>
  );
};

export default MultifilteredDataTable;
