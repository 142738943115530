import React from "react";

import {
  createStyles,
  Theme,
  makeStyles,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

import deleteLogo from "../../../../../assets/Delete.png";

import useApi from "../../../../../hooks/useApi";
import { useApplicationContext } from "../../../../../contexts/ApplicationContext";

const textFieldBorder = "1px solid #464659";
const displayVar = "inline-block";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hidden: {
      display: "none",
    },
    formControl: {
      margin: "0 25px",
    },
    gridRoot: {
      flexGrow: 1,
      padding: "0px 24px",
      backgroundColor: "#2B2B36",
      margin: "0px 20px 0px 20px",
      borderBottom: "solid thin #33333F",
    },
    gridRootApp: {
      flexGrow: 1,
      padding: "0",
      margin: "0 20px 20px",
    },
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    flexContainer: {
      display: "flex",
      flexDirection: "row",
    },
    listButtonWrap: {
      marginLeft: "20px",
    },
    listItem: {
      width: "auto",
      padding: "0px",
      marginRight: "20px",
      fontSize: "16px",
      color: "#FFFFFF",
      lineHeight: "19px",
    },
    colorWhite: {
      color: "#FFFFFF",
    },
    listItemText: {
      fontSize: "16px",
      lineHeight: "19px",
    },
    tableWrapper: {
      padding: "0 20px",
      marginTop: "15px",
      boxShadow: "none",
    },
    table: {
      minWidth: "100%",
      maxWidth: "100%",
    },
    listButton: {
      padding: "0px",
      textTransform: "none",
      fontSize: "14px",
      whiteSpace: "nowrap",
    },
    rightSec: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      whiteSpace: "nowrap",
      padding: "5px 0px",
      fontSize: "12px",
      lineHeight: "14px",
      "& p": {
        fontSize: "12px",
        padding: "11px 0px",
        marginRight: "30px",
      },
      "& button": {
        background: "#0089FF",
        borderRadius: "2px",
        fontSize: "16px",
        color: "#FCFCFC",
        padding: "6px 20px",
        marginRight: "10px",
        "&:hover": {
          background: "#0089FF",
        },
      },
    },
    menuButtonRoot: {
      padding: "0px",
    },
    viewDetails: {
      "&:hover": {
        color: "#0089FF !important",
      },
    },
    menuRoot: {
      backgroundColor: "#1D1D25",
      border: "1px solid #33333F",
      boxShadow: "0px 4px 4px rgba(0,0,0,0.25)",
      borderRadius: "2px",
    },
    menuitem: {
      color: "white",
      fontSize: "14px",
      paddingRight: "40px",
      "&:hover": {
        color: "#0089FF !important",
      },
      "& svg": {
        marginRight: "8px",
      },
    },
    dialogRoot: {
      boxShadow: "0px 30px 100px #000000",
      borderRadius: "3px",
      maxWidth: "800px",
      minWidth: "800px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "2px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "10px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
      },
      "& .dcSep": {
        marginTop: "10px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: "1px solid rgba(235,235,245,0.35)",
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    icon: {
      color: "#FFFFFF",
    },
    options: {
      color: "#FCFCFC",
      fontSize: "12px",
      padding: "10px",
      cursor: "pointer",
    },
    fieldRoot: {
      marginTop: "16px",
    },
    spaceRight: {
      paddingRight: "10px",
    },
    fieldWrapper: {
      marginTop: "20px",
    },
    dropdown: {
      width: "207px",
      height: "40px",
      background: "transparent",
      padding: "10px",
      color: "#FFFFFF",
      border: "1px solid #4CA5FF",
      font: "400 13.3333px 'Avenir Next'",
      borderRadius: "2px",
      marginRight: "30px",
      "& option": {
        background: "#1D1D25",
      },
    },
    label: {
      fontSize: "18px",
      lineHeight: "21px",
      marginRight: "18px",
      color: "#FFF",
    },
    alignCentre: {
      alignItems: "center",
      marginTop: "0.5rem",
    },
    dialogRootSmall: {
      boxShadow: "0px 30px 100px #000000",
      borderRadius: "3px",
      maxWidth: "800px",
      position: "absolute",
      top: "10%",
      minWidth: "416px",
      "& .dcTitle.dcHeader": {
        marginTop: "0px",
      },
      "& .dcTitle": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        fontWeight: "bold",
        marginTop: "25px",
      },
      "& .dcOuter": {
        background: "#2B2B36",
        borderRadius: "4px",
        padding: "10px",
      },
      "& .dcFieldValue": {
        fontSize: "14px",
        lineHeight: "16px",
        marginTop: "10px",
        color: "#FFFFFF",
      },
      "& .dcSep": {
        marginTop: "20px",
      },
      "& .dcBtDelete": {
        borderRadius: "2px",
        border: "1px solid rgba(235,235,245,0.35)",
        color: "#0089FF",
        background: "none",
      },
      "& .dcBtEdit": {
        backgroundColor: "#0089ff",
        borderRadius: "4px",
        border: "none",
        color: "#FFFFFF",
      },
    },
    field: {
      color: "#FCFCFC",
      fontSize: "12px",
      padding: "10px",
    },
    textfield: {
      color: "#ffffff",
      backgroundColor: "#1D1D25",
      border: `${textFieldBorder}`,
      borderRadius: "2px",
      boxSizing: "border-box",
    },
    dividerColor: {
      background: "#464659",
      margin: "1em",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    tblpaginate: {
      color: "#fff",
    },
    labels: {
      color: "#fcfcfc",
      lineHeight: "16px",
      fontSize: "14px",
      transform: "scale(1)",
    },
    tblfooter: {
      position: "absolute",
      display: `${displayVar}`,
    },
    tblfooter1: {
      position: "sticky",
      padding: "10px 0",
      display: "flex",
      alignItems: "center",
    },
    tblfooter2: {
      position: "sticky",
      right: "0",
      padding: "20px 25px",
      display: "flex",
      marginLeft: "87.4rem",
      marginTop: "-58px",
    },
    tblpaginatenumber: {
      color: "#fff",
      marginRight: "13px",
    },
    paginateUser: {
      color: "#fff",
    },
    btnTableCell: {
      color: "#FFFFFF",
      padding: "8px 0",
      fontWeight: "bold",
      borderBottom: "none",
      backgroundColor: "#464659",
      textTransform: "capitalize",
      fontSize: "0.875rem",
    },
    lblFilterBox: {
      color: "#FFFFFF",
    },
    popoverInputSearch: {
      margin: "15px",
      border: "1px solid white",
    },
    space2Top: {
      marginTop: "5px",
    },
    bottomSection: {
      justifyContent: "space-between",
      display: "flex",
      borderTop: "1px solid #33333F",
      paddingTop: "7px",
      paddingBottom: "7px",
    },
    btnCancel: {
      color: "gray",
      fontSize: "15px",
      textTransform: "capitalize",
    },
    btnAddNUser: {
      color: "#0089ff",
      fontSize: "15px",
      textTransform: "capitalize",
    },
    formControlOvrflw: {
      overflowY: "scroll",
      margin: "0 25px",
      width: "90%",
      maxHeight: "250px",
    },
    paginateView: {
      color: "#fff",
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
      color: "#FFFFFF",
    },
    iconButton: {
      padding: 10,
      color: "#FFFFFF",
    },
    permissionError: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      color: "white",
      height: "80vh",
      width: "100%",
    },
  })
);

const DialogTitleStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: "20px",
      padding: 0,
      color: "#FFFFFF",
    },
    title: {
      fontSize: "21px",
      lineHeight: "25px",
      fontWeight: "bold",
    },
    closeButton: {
      position: "absolute",
      right: "2px",
      top: "15px",
      color: "#FFFFFF",
      fontSize: "14px",
    },
  });
const DialogContent = withStyles((theme: Theme) => ({
  root: {
    margin: "0px 15px",
    padding: 0,
    color: "#FFFFFF",
  },
}))(MuiDialogContent);
const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: "20px 15px",
    justifyContent: "space-between",
    padding: 0,
    "& button": {
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
  },
}))(MuiDialogActions);

export interface DialogTitleProps extends WithStyles<typeof DialogTitleStyles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}
const DialogTitle = withStyles(DialogTitleStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

//TODO: Remove unwanted classes. Component not refactored. Taken as is from older page

const DeleteUser: React.FC<any> = ({ record, onDone }) => {
  const classes = useStyles();
  const history = useHistory();
  const { selectedApplication } = useApplicationContext();
  const closeAndRefreshGrid = () => history.push("/remount");
  const { enqueueSnackbar } = useSnackbar();

  const { trigger: deleteUser } = useApi(
    `/applications/${selectedApplication.application_id}/users/${record.user_id}`,
    {
      method: "DELETE",
      deferred: true,
      responseDataFormatter: (response: any) => {
        console.log({ response });
        enqueueSnackbar("User Deleted", { variant: "success" });
        closeAndRefreshGrid();
      },
      onError: (error) => {
        enqueueSnackbar("An error occured while deleting user", {
          variant: "error",
        });
        closeAndRefreshGrid();
      },
    }
  );

  return (
    <Dialog
      classes={{ paper: classes.dialogRootSmall }}
      onClose={onDone}
      aria-labelledby="customized-dialog-title"
      open={true}
    >
      <Grid container direction="row" alignItems="center">
        <img
          style={{ marginLeft: "1em" }}
          alt="Delete Logo"
          src={deleteLogo}
        ></img>
        <DialogTitle id="customized-dialog-title" onClose={onDone}>
          Delete User?
        </DialogTitle>
      </Grid>
      <DialogContent>
        <Typography
          style={{
            fontFamily: "Avenir Next",
            fontSize: "14px",
            lineHeight: "16px",
            display: "flex",
            alignItems: "center",
            margin: "1em",
            color: "#FFFFFF",
          }}
        >
          Are you sure you want to delete this User? This User cannot be
          recovered.
        </Typography>
      </DialogContent>
      <Divider variant="middle" classes={{ middle: classes.dividerColor }} />

      <DialogActions>
        <Button onClick={onDone} variant="outlined" className="dcBtDelete">
          Cancel
        </Button>
        <Button
          onClick={() => {
            deleteUser();
          }}
          variant="contained"
          className="dcBtEdit"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteUser;
